<template>
    <div class="calculator">
        <div v-if="show30 == true" id="card30day" class="card">
            <div class="card-body">
                <h5 class="card-title">30 Day Readmission Rate</h5>
                
                <h1>Score: {{this.c30}} %</h1>
                <form>
                        <label for="bmi30" class="col-sm-2 col-form-label pl5px">BMI</label> 
                        <input v-model.number="calc30.bmi" class="col-sm-4" type="number" id="bmi30">

                        <label for="age30" class="col-sm-2 col-form-label pl5px">Age</label> 
                        <input v-model.number="calc30.age" class="col-sm-4" type="number" id="age30">

                        <label for="pta30" class="col-sm-2 col-form-label pl5px">PTA Meds</label> 
                        <input v-model.number="calc30.ptaMeds" class="col-sm-4" type="number" id="pta30">

                        <label for="edVisits30" class="col-sm-2 col-form-label pl5px">ED Visits</label> 
                        <input v-model.number="calc30.edVisits" class="col-sm-4" type="number" id="edVisits30">

                        <label for="gender30" class="col-sm-2 col-form-label pl5px">Gender</label> 
                        <select v-model="calc30.gender" id="gender30" class="col-sm-2">
                            <option selected>Female</option>
                            <option>Male</option>
                        </select>

                        <label for="dd30" class="col-sm-4 col-form-label pl5px">Dicharge Disposition</label> 
                        <select v-model="calc30.dischargeDisposition" id="dd30" class="col-sm-4" >
                            <option selected>Home or Self Care</option>
                            <option>Home Health Care Svc</option>
                            <option>Facility</option>
                        </select>


                        <label for="cdhf30" class="col-sm-2 col-form-label pl5px">CD/HF</label> 
                        <select v-model="calc30.cdhf" id="cdhf30" class="col-sm-3" >
                            <option selected>None</option>
                            <option>CD</option>
                            <option>HF</option>
                            <option>Both</option>
                        </select>


                        <label for="pdu30" class="col-sm-4 col-form-label pl5px">Psychiatric Drug Use</label> 
                        <input type="checkbox" id="pdu30" class="col-sm-3" v-model="calc30.psychiatricDrugUse">

    
                </form>           
            </div>
        </div>

        <div v-if="show30 == false" id="card90day" class="card"> 
            <div class="card-body">
                <h5 class="card-title">90 Day Readmission Rate</h5>

                <h1>Score: {{this.c90}} %</h1>

                <label for="bmi90" class="col-sm-3 col-form-label pl5px">BMI</label> 
                <input v-model.number="calc90.bmi" class="col-sm-3" type="number" id="bmi90">

                <label for="pta90" class="col-sm-3 col-form-label pl5px">PTA Meds</label> 
                <input v-model.number="calc90.ptaMeds" class="col-sm-3" type="number" id="pta90">

                <label for="tjr90" class="col-sm-3 col-form-label pl5px">TJR &lt; 12 Mo's</label> 
                <input v-model.number="calc90.TJR" class="col-sm-3" type="checkbox" id="tjr90">

                <label for="gender90" class="col-sm-3 col-form-label pl5px">Gender</label> 
                <select v-model="calc90.gender" id="gender90" class="col-sm-3">
                    <option selected>Female</option>
                    <option>Male</option>
                </select>

                <label for="edVisits90" class="col-sm-3 col-form-label pl5px">ED Visits</label> 
                <input v-model.number="calc90.edVisits" class="col-sm-3" type="number" id="edVisits90">

                <label for="dd90" class="col-sm-3 col-form-label pl5px">Dicharge Disposition</label> 
                <select v-model="calc90.dischargeDisposition" id="dd90" class="col-sm-3" >
                    <option selected>Home or Self Care</option>
                    <option>Home Health Care Svc</option>
                    <option>Facility</option>
                </select>

                <label for="cdhf90" class="col-sm-3 col-form-label pl5px">CD/HF</label> 
                <select v-model="calc90.cdhf" id="cdhf90" class="col-sm-3" >
                    <option selected>None</option>
                    <option>CD</option>
                    <option>HF</option>
                    <option>Both</option>
                </select>

                <label for="iv90" class="col-sm-3 col-form-label pl5px">IV Drug Abuse</label> 
                <input v-model.number="calc90.ivDrugAbuse" class="col-sm-3" type="checkbox" id="iv90">

                <label for="narc90" class="col-sm-3 col-form-label pl5px">Narcotic Meds</label> 
                <input v-model.number="calc90.narcoticMeds" class="col-sm-3" type="checkbox" id="narc90">


            </div>
        </div>
        <div class="row">
            <div class="col-sm"> 
                <button type="button" class="btn btn-outline-secondary p-2 mr5t5" v-on:click="show30 = true">30-Day</button>
                <button type="button" class="btn btn-outline-secondary p-2 mr5t5" v-on:click="show30 = false">90-Day</button>
            </div>

        </div>

    </div>
</template>

<script>
    export default {
        name: 'Calculator',
        data() {
            return {
                componentLoaded: false,
                show30: true,
                calc30: {
                    bmi: 33.4,
                    ptaMeds: 10.54,
                    age: 64.25,
                    gender: "Female",
                    edVisits: 10,
                    dischargeDisposition: "Home Health Care Svc",
                    cdhf: "None",
                    psychiatricDrugUse: false,
                    result: null,
                },
                
                calc90: {
                    bmi: 35,
                    ptaMeds: 10,
                    TJR: true,
                    gender: "Female",
                    edVisits: 5,
                    dischargeDisposition: "Home or Self Care",
                    cdhf: "None",
                    ivDrugAbuse: false,
                    narcoticMeds: false
                }
            }
        },
        methods: {
            calculate30() {
                let base = -2.6576;
                let calcAge = 0.0291 * this.calc30.age;
                let calcBMI =  - (0.1345 * this.calc30.bmi) + (0.00218 * this.calc30.bmi * this.calc30.bmi); 
                let calcGender = this.calc30.gender == "Male" ?  0.207 :  0;
                console.log("gender: " + calcGender)
                
                function calcDischargeDisposition(dd) {
                    if (dd == "Home or Self Care") { return -0.3124 }
                    if (dd == "Home Health Care Svc") {return 0 }
                    if (dd == "Facility") { return 0.3645 }
                }
                function calcCDHF(cdhf) {
                    if (cdhf == "Both") { return 0.7994 } 
                    if (cdhf == "CD") { return -0.0505 }
                    if (cdhf == "HF") { return -0.3669 }
                    if (cdhf == "None") { return 0 }
                }
                let calcEdVisits = this.calc30.edVisits > 9 ? 0.5942 : 0;
                let calcPsychDrugUse = this.calc30.psychiatricDrugUse ? 0 : -0.1934;
                let calcPTAMeds = 0.0332 * this.calc30.ptaMeds;
                let subtotal = base + calcAge + calcBMI + calcGender + calcDischargeDisposition(this.calc30.dischargeDisposition) + calcCDHF(this.calc30.cdhf) + calcEdVisits + calcPsychDrugUse + calcPTAMeds;
                let total = Math.exp(subtotal) / (1 + Math.exp(subtotal))
                return parseFloat(total).toFixed(2) * 100;
            },
            calculate90(){
                let base = -0.5527;
                console.log(base);
                let calcBMI = - (this.calc90.bmi * 0.0903) + (this.calc90.bmi * this.calc90.bmi * 0.00145);
                console.log(calcBMI);
                let calcGender = this.calc90.gender == "Male" ? 0.2241: 0;
                console.log(calcGender);
                function calcDischargeDisposition(dd) {
                    if (dd == "Home or Self Care") { return -0.2464 }
                    if (dd == "Home Health Care Svc") {return 0 }
                    if (dd == "Facility") { return 0.3233 }
                }
                console.log("dd" + calcDischargeDisposition(this.calc90.dischargeDisposition));
                function calcCDHF(cdhf) {
                    console.log(cdhf);
                    if (cdhf == "Both") { return 0.7544 } 
                    if (cdhf == "CD") { return -0.1169 }
                    if (cdhf == "HF") { return -0.1284 }
                    if (cdhf == "None") { return 0 }
                }
                console.log("cdhf" + calcCDHF(this.calc90.cdhf));
                let calcEdVisits = this.calc90.edVisits > 9 ? 0.3325 : 0;
                console.log("ed visits: " + calcEdVisits);
                let calcNarcoticMeds = this.calc90.narcoticMeds == false ? -0.1296 : 0;
                console.log("narcs: " + calcNarcoticMeds);
                let calcIVDrugAbuse = this.calc90.ivDrugAbuse == false ? -0.2475: 0;
                console.log("iv: " + calcIVDrugAbuse);
                let calcPTAMeds = 0.0193 * this.calc90.ptaMeds;
                console.log("pta: " + calcPTAMeds);
                let calc12MonthTJR = this.calc90.TJR == true ? 0.382: 0;
                console.log("tjr: " + calc12MonthTJR);
                
                let subtotal = base 
                 + calcBMI 
                 + calcGender 
                 + calcDischargeDisposition(this.calc90.dischargeDisposition)
                 + calcCDHF(this.calc90.cdhf) 
                 + calcEdVisits + calcNarcoticMeds
                 + calcIVDrugAbuse 
                 + calcPTAMeds 
                 + calc12MonthTJR;
                
                console.log("sub" + subtotal);
                let total = Math.exp(subtotal) / (1 + Math.exp(subtotal));
                return parseFloat(total).toFixed(2) * 100;
            
            }
        },
        computed: {
            c30: function() {
                if (this.componentLoaded) {
                    return this.calculate30();
                } else {
                    return 0;
                }
                
            },
            c90: function() {
                if (this.componentLoaded) {
                    return this.calculate90();
                } else {
                    return 0;
                }
                
            }
        },
        
        mounted() {
            this.componentLoaded = true;
            this.calculate30();
            this.calculate90();
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h3 {
        margin: 40px 0 0;
    }
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 10px;
    }
    a {
        color: #42b983;
    }
    .pl5px {
        padding-left: 5px !important;
    }
    .mr5t5{
        margin-right: 5px !important;
        margin-top: 5px !important;
    }

</style>
