<template>
<div>
    <nav class="navbar navbar-light bg-light">
             <img src = "@/assets/logo.svg" alt="OrthoLive" class="img-responsive" width="200px"/>
    </nav>
    <div id="app" class="container text-left m920">
        <div class="row">
            <div class="col"> 
                <Calculator/>
            </div> 
      </div>

    
</div>
</template>

<script>
import Calculator from './components/Calculator.vue'
export default {
  name: 'App',
  components: {
    Calculator
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  margin-top: 25px;
}

svg {
      width:  auto;
      height: auto;
}

.m920 {
        max-width: 920px !important;
    }
</style>
